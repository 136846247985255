/** @format */

import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#fff",
    color: "#000",
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(8),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(10),
  },
  title: {
    fontWeight: "100 !important",
    marginBottom: theme.spacing(2),
    textAlign: "center",
    fontFamily: "'Raleway', sans-serif !important",
  },
  subtitle: {
    fontWeight: 300,
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    textAlign: "center",
    maxWidth: "400px",
    fontFamily: "'Raleway', sans-serif !important",
  },
  form: {
    marginTop: theme.spacing(10),
    width: "100%",
    maxWidth: "600px",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    width: "24%",
    borderRadius: "55px !important",
    backgroundColor: "rgba(216, 216, 216, 1) !important",
    boxShadow: "none !important",
    color: "#fff",
    "&:hover": {
      background:
        "linear-gradient(to right, #FF9250, #FF6473, #FE26A0, #CF34B9, #7852DC, #017AFF) !important",
    },
  },
}));

const ContactPage = () => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const initialFormState = {
    name: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData(initialFormState);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch("https://api.eon.health/contact_form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Message sent successfully!",
          severity: "success",
        });
        resetForm();
      } else {
        setSnackbar({
          open: true,
          message: "Failed to send message. Please try again.",
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: "An unexpected error occurred. Please try again later.",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.content}>
          <Typography variant="h2" className={classes.title}>
            Contact Us
          </Typography>
          <Box
            width="160px"
            height="1px"
            bgcolor="#000"
            margin="auto"
            marginBottom="16px"
            marginTop={2}
          />
          <Typography variant="body1" className={classes.subtitle}>
            By analyzing information of you which is collected from six
            different pillars, Our algorithm assesses and suggests the best way
            to enhance your longevity.
          </Typography>

          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={classes.input}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={classes.input}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  value={formData.message}
                  onChange={handleInputChange}
                  className={classes.input}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.submitButton}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Sending..." : "Send Email"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              variant="filled"
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Container>
    </Box>
  );
};

export default ContactPage;
