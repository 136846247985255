/** @format */

// components/LongevityPillars.tsx
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import nutritionImg from "../assets/nutrition.png";
import movementImg from "../assets/movement.png";
import cognitionImg from "../assets/cognition.png";

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    paddingTop: theme.spacing(18),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    paddingBottom: theme.spacing(12),
  },
  cardContainer: {
    position: "relative",
  },
  card: {
    height: "100%",
    backgroundColor: "transparent !important",
    color: "inherit",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    transition: "background-color 0.3s ease",
    // keep opacity at 0.4 when not hovered
    // and 1 when hovered
    opacity: 0.4,
    "&:hover": {
      opacity: 1,
    },
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  travelingBorder: {
    position: "absolute",
    border: "2px solid",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(to right bottom, #ff7566, #fe3099, #c638bd, #af40c6, #605ae3, #017aff)",
    transition: "all 0.4s cubic-bezier(0.25, 0.1, 0.25, 1.0)",
    pointerEvents: "none",
    zIndex: 1,
  },
  pillarTitle: {
    color: "#ffffff",
    paddingTop: theme.spacing(2),
  },
  pillarDescription: {
    color: "#ffffff",
    maxWidth: 200,
    margin: "0 auto",
    textAlign: "center",
    paddingTop: theme.spacing(4),
  },
}));

interface PillarItem {
  title: string;
  description: string;
  img: string;
}

const pillarItems: PillarItem[] = [
  {
    title: "Recovery",
    description:
      "Essential for cellular repair and\
 regeneration, recovery encompasses\
 quality sleep, stress management,\
 and relaxation techniques.\
 Proper recovery allows your body to\
 heal, recharge, and maintain optimal\
 function.",
    img: nutritionImg,
  },
  {
    title: "Nutrition",
    description:
      "Fuel your body with the right\
 balance of nutrients.\
 Our personalized nutrition\
 strategies consider your unique needs\
 and lifestyle to optimize your diet for\
 longevity and vitality.",
    img: nutritionImg,
  },
  {
    title: "Movement",
    description:
      "Regular physical activity is crucial\
 for maintaining strength, flexibility\
, and cardiovascular health.\
\
 We help you design a sustainable\
 exercise routine that keeps your body\
 resilient and functional as you age. ",
    img: movementImg,
  },
  {
    title: "Connection",
    description:
      "Social bonds and emotional well-being\
 play a vital role in longevity.\
\
We emphasize the importance of\
 meaningful relationships, community\
 engagement, and mental health support\
 to enhance your overall quality of life. ",
    img: cognitionImg,
  },
  {
    title: "Cognition",
    description:
      "Keep your mind sharp and adaptable.\
\
Our cognitive health strategies focus\
 on mental stimulation, continuous\
 learning, and brain-protective habits\
 to maintain cognitive function and\
 prevent age-related decline.",
    img: cognitionImg,
  },
  {
    title: "Aesthetics",
    description:
      "Looking good often translates to\
feeling good.\
\
We address aspects of physical\
 appearance that contribute to\
 self-confidence, performance and\
 overall well-being, including skin\
 health, posture, hair & dental health.",
    img: movementImg,
  },
];

const sixPillarDescription =
  "Our bodies are interconnected systems where every aspect influences the whole.\
  EON.HEALTH's approach to longevity recognizes this complexity,\
  focusing on six key pillars that work in harmony to promote overall health and extended lifespan.";

const LongevityPillars: React.FC = () => {
  const classes = useStyles();
  const [borderStyle, setBorderStyle] = useState<{
    left?: string;
    top?: string;
    width?: string;
    height?: string;
    opacity?: number;
  }>({ opacity: 0 });
  const gridRef = useRef<HTMLDivElement>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);
  const [isMouseInGrid, setIsMouseInGrid] = useState(false);

  const updateBorderStyle = useCallback(() => {
    if (!gridRef.current || !isMouseInGrid) {
      setBorderStyle({ opacity: 0 });
      setActiveCardIndex(null);
      return;
    }

    const containerRect = gridRef.current.getBoundingClientRect();
    const mouseX = mousePosition.x - containerRect.left;
    const mouseY = mousePosition.y - containerRect.top;

    let closestCard: HTMLDivElement | null = null;
    let minDistance = Infinity;
    let closestIndex = -1;

    cardRefs.current.forEach((cardRef, index) => {
      if (cardRef) {
        const cardRect = cardRef.getBoundingClientRect();
        const cardCenterX =
          cardRect.left + cardRect.width / 2 - containerRect.left;
        const cardCenterY =
          cardRect.top + cardRect.height / 2 - containerRect.top;

        const distance = Math.sqrt(
          Math.pow(mouseX - cardCenterX, 2) + Math.pow(mouseY - cardCenterY, 2)
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestCard = cardRef;
          closestIndex = index;
        }
      }
    });

    if (closestCard) {
      const cardRect = (closestCard as HTMLDivElement).getBoundingClientRect();
      setBorderStyle({
        left: `${cardRect.left - containerRect.left}px`,
        top: `${cardRect.top - containerRect.top}px`,
        width: `${cardRect.width}px`,
        height: `${cardRect.height}px`,
        opacity: 1,
      });
      setActiveCardIndex(closestIndex);
    } else {
      setBorderStyle({ opacity: 0 });
      setActiveCardIndex(null);
    }
  }, [mousePosition, isMouseInGrid]);

  useEffect(() => {
    updateBorderStyle();
  }, [updateBorderStyle]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    const handleScroll = () => {
      updateBorderStyle();
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [updateBorderStyle]);

  const handleMouseEnter = () => {
    setIsMouseInGrid(true);
  };

  const handleMouseLeave = () => {
    setIsMouseInGrid(false);
    setBorderStyle({ opacity: 0 });
    setActiveCardIndex(null);
  };

  return (
    <Box className={classes.section}>
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Longevity Six Pillars
        </Typography>
        <Box
          sx={{
            width: "200px",
            height: "2px",
            backgroundColor: "#ffffff",
            margin: "0 auto",
            mb: 4,
            mt: 4,
          }}
        />
        <Typography
          variant="body1"
          align="center"
          gutterBottom
          sx={{ mb: 15, maxWidth: 800, mx: "auto", mt: 10 }}
        >
          {sixPillarDescription}
        </Typography>
        <Box
          className={classes.cardContainer}
          ref={gridRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Grid container spacing={4}>
            {pillarItems.map((item, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card
                  className={classes.card}
                  ref={(el) => (cardRefs.current[index] = el)}
                  style={{
                    opacity: activeCardIndex === index ? 1 : 0.4,
                    transition: "opacity 0.3s ease",
                  }}
                >
                  <CardContent className={classes.cardContent}>
                    <img
                      src={item.img}
                      alt={item.title}
                      width={60}
                      height={60}
                      style={{ display: "block", margin: "0 auto" }}
                    />
                    <Typography
                      variant="h6"
                      gutterBottom
                      align="center"
                      className={classes.pillarTitle}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        width: "120px",
                        height: "2px",
                        margin: "0 auto",
                        background:
                          "linear-gradient(to right, #ff7c61, #fa27a2)",
                        mb: 2,
                        mt: 2,
                      }}
                    />
                    <Typography
                      variant="body2"
                      gutterBottom
                      align="center"
                      className={classes.pillarDescription}
                    >
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box className={classes.travelingBorder} style={borderStyle} />
        </Box>
      </Container>
    </Box>
  );
};

export default LongevityPillars;
